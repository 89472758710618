import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Home from './Home';
import About from './About';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main_Logo from './images/main/Logo.png'
// import Main_Logo from './images/main/Logo_1.jpeg'
import Portfolio from './my-content/Portfolio';
import Team from './my-content/Team';
import Footer from './Footer';
import MediaCoverage from './my-content/MediaCoverage';
import Awards from './my-content/Awards';

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/portfolio">
          <ListItemText primary="Portfolio" />
        </ListItem>
        <ListItem button component={Link} to="/team">
          <ListItemText primary="Team" />
        </ListItem>
        <ListItem button component={Link} to="/awards">
          <ListItemText primary="Awards" />
        </ListItem>
        <ListItem button component={Link} to="/media-coverage">
          <ListItemText primary="Media Coverage" />
        </ListItem>
        <ListItem button component={Link} to="/contact">
          <ListItemText primary="Contact Us" />
        </ListItem>
      </List>
    </div>
  );
  return (
    <Router>
      <div className="App">
        <header className="App-header sticky-header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <img src={Main_Logo} className="App-logo" alt="logo" style={{ marginLeft: '60px', marginTop: '10px', marginBottom: '10px', width: '140px', height: '80px' }}/>
              {isMobile ? (
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  style={{ marginLeft: 'auto', marginRight: '5px', backgroundColor: '#dedede' }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                  {drawerContent}
                </Drawer>
              </>
            ) : (
              <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav" style={{ marginRight: '30px', fontSize: '20px', fontWeight: '500'}}>
                  <li className="nav-item" >
                    <Link className="nav-link" to="/" style={{ marginRight: '30px'}}>Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/portfolio" style={{ marginRight: '30px'}}>Portfolio</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/team" style={{ marginRight: '30px'}}>Team</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/awards" style={{ marginRight: '30px'}}>Awards</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/media-coverage" style={{ marginRight: '30px'}}>Media Coverage</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/team" style={{ marginRight: '30px'}}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            )}
            </div>
    
          </nav>
        </header>
        <div className="container mt-4">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/team" element={<Team />} />

            <Route path="/awards" element={<Awards />} />
            <Route path="/media-coverage" element={<MediaCoverage />} />
            <Route path="/contact" element={<About />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
