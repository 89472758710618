import React from 'react';
import slider_1 from './images/main/Home Page Paintings/Narender Modi RB Santosh.jpeg';
import slider_2 from './images/main/Home Page Paintings/Narendermodirbsantosh2.jpeg';
import slider_3 from './images/main/Home Page Paintings/pranab.jpg';
import slider_4 from './images/main/Home Page Paintings/pranab2.jpg';
import slider_5 from './images/main/Home Page Paintings/premadasa.jpeg';
import slider_6 from './images/main/Home Page Paintings/Madhuri4.jpg';
import slider_7 from './images/main/Home Page Paintings/Madhuri Painting.jpg';
import slider_8 from './images/main/Home Page Paintings/virat-kohli.jpg';
import { useMediaQuery, useTheme } from '@mui/material';

import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'


const Home = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const images = [
        { src: slider_2, width: '100%', alt: "Image 2", text: "With Indian Prime Minister", icon: "icon2.png" },
        { src: slider_1, width: '80%', alt: "Image 1", text: "With Indian Prime Minister", icon: "icon1.png" },
        { src: slider_3, width: '100%', alt: "Image 3", text: "With the President of India", icon: "icon2.png" },
        { src: slider_4, width: '100%', alt: "Image 4", text: "With the President of India", icon: "icon2.png" },
        { src: (require('./images/main/Home Page Paintings/9.jpeg')), width: '100%', alt: "Image 4", text: "With the president of Maldives", icon: "icon2.png" },
        { src: slider_5, width: '60%', alt: "Image 5", text: "With the Prime Minister of Sri Lanka", icon: "icon2.png" },
        { src: slider_6, width: '100%', alt: "Image 3", text: "Receiving Finest Contemporary Artist in India award from Ms Madhuri Dixit.", icon: "icon3.png" },
        { src: slider_7, width: '100%', alt: "Image 3", text: "Presenting Painting to Ms Madhuri Dixit.", icon: "icon3.png" },
        { src: slider_8, width: '100%', alt: "Image 3", text: "Presenting Man of the series award to Virat Kohli.", icon: "icon3.png" },
        { srcs: [require('./images/main/Home Page Paintings/10.jpeg'), require('./images/main/Home Page Paintings/11.jpeg')], width: '60%', alt: "Image 5", text: "With Dr Shashi Tharoor", icon: "icon2.png" },
        { src: (require('./images/main/Home Page Paintings/Harvard.jpeg')), width: '100%', alt: "Image 3", text: "Harvard Business School Graduation", icon: "icon3.png" },
    ];

    return (
        <>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Carousel>
                    {images.map((image, index) => (
                        <Carousel.Item key={index} interval={3000}>
                            {image.srcs ?
                                <div
                                className="carousel-image-wrapper"
                                style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}
                            >
                                {image.srcs.map((src, i) => (
                                    <img
                                        key={i}
                                        className="d-block"
                                        style={{
                                            width: '45%',
                                            height: `${isMobile ? 'auto' : '600px'}`,
                                            objectFit: 'cover',
                                            margin: i === 0 ? '0 5px 0 0' : '0 0 0 5px',
                                            border: '2px solid white',
                                        }}
                                        src={src}
                                        alt={image.alt}
                                    />
                                ))}
                            </div>
                                :

                                <div
                                    className="carousel-image-wrapper"
                                    style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <img
                                        className="d-block"
                                        style={{
                                            maxWidth: '100%', // Ensure it fits within the height
                                            maxHeight: `${isMobile ? '100%' : '600px'}`,  // Ensure it fits within the width
                                            objectFit: 'contain', // Contain the image within the container
                                        }}
                                        // style={{
                                        //     // width: `${image.width} !important`,
                                        //     maxHeight: '600px',
                                        //     // border: '5px solid white'
                                        // }}
                                        src={image.src}
                                        alt={image.alt}
                                    />
                                </div>
                            }
                            {image.text && <Carousel.Caption>
                                <div
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adding opacity background
                                        padding: '10px',
                                        position: 'absolute',
                                        bottom: '0px', // Positioning at the bottom
                                        left: '0',
                                        right: '0',
                                        textAlign: 'center',
                                    }}
                                >
                                    {isMobile ?
                                        <small style={{ fontSize: '10px', color: 'white', fontWeight: '200' }}>{image.text}</small> :
                                        <h2 style={{ color: 'white', fontWeight: '200' }}>{image.text}</h2>}
                                </div>
                            </Carousel.Caption>}
                        </Carousel.Item>
                    ))}
                </Carousel>
                <br />
                <span className='homeDetails'>
                    RBSK Arts is one of the leading Global art company engaged in the business of producing art in various formats The company has varied business verticals and is a market leader in production of sculptures, Murals, Graffiti, Oil & Acrylic Paintings, Interior Designs, Digital Painting, Film Set designs, Tourism Park Design. The company is also engaged in production of movies ,Television serials  Documentary films, Tele films, . The company also organizes cultural events like Music and dance shows. RBSK Arts team members have won several awards in the filed of Art.
                </span>
                {/* <br />
            <span className='homeDetails'>
                His  paintings have been picked  up by Art lovers and are being  valued as the choice of the discerning collectors and Investors of Art . He is a recipient of a silver medal from the President of India. As of today some of his paintings adorn the walls of leading  Corporate houses and  high society  residences across the Globe. Santosh has worked for the Taj Group of Hotels , Times of India, Standard Chartered Bank , Citibank and Mastercard n a career spanning over 32 years.
            </span> */}
            </div>
        </>
    );
}

export default Home;
