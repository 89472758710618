import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const allImages = {
    images: [
      { src: require('../images/main/Home Page Paintings/Madhuri4.jpg'), alt: 'Image 1' },
      { src: require('../images/main/Home Page Paintings/virat-kohli.jpg'), alt: 'Image 2' },
      { src: require('../images/awards/1.jpeg'), alt: 'Image 3' },

    ],
};

const Awards = () => {
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleShow = (index) => {
    setCurrentIndex(index);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages.images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + allImages.images.length) % allImages.images.length);
  };

  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          {allImages.images.map((image, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <img
                src={image.src}
                alt={image.alt}
                className="img-fluid"
                style={{ height: '200px', width: '280px', cursor: 'pointer' }}
                onClick={() => handleShow(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Modal for full-screen image display */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body style={{ backgroundColor: 'black', position: 'relative', padding: 0 }}>
          <Button variant="secondary" onClick={prevImage} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}>
            &lt;
          </Button>
          <img
            src={allImages.images[currentIndex].src}
            alt={allImages.images[currentIndex].alt}
            className="img-fluid"
            style={{ maxHeight: '80vh', display: 'block', margin: '0 auto' }}
          />
          <Button variant="secondary" onClick={nextImage} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}>
            &gt;
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Awards;
