import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './team.css'

const allImages = {
    all: {
        label: 'Management Team',
        content: [
            { src: require('../images/team/RBSKPhoto.jpg'),
              alt: 'Image 1', 
              name: 'RB Santosh Kumar', 
              details: 'R B  Santosh Kumar,Chairman of RBSK Arts is a Global Artist and holds 15 Solo exhibitions and 15 International  group shows to his credit. He is also an Alumni of Harvard Business School, Boston. In 2023, Santosh won the award for the finest contemporary artist in India. He works with oil, Acrylic and water color mediums in his artistic creations.  He believes in the human form , its myriad postures and the variety of expressions and translates them in his art with the grace of an artist and the authenticity of a sensitive human being. His paintings cover various subjects ranging from still art to natural landscapes, abstracts as well as  human figures. Each of his paintings capture a moment  or feeling that are pristine and deliver a magical experience to the viewer. Every frame exhibits proficient strokes in a different medium  and various colors of the palette reflecting the artists love for a joyful and harmonious everyday life. Santosh has earned credibility as an artist and some of his works  are displayed at the Rashtrapati Bhavan in India (Official Residence and Office of the President of India ).His  paintings have been picked  up by Art lovers and are being  valued as the choice of the discerning collectors and Investors of Art . He is a recipient of a silver medal from the President of India. As of today some of his paintings adorn the walls of leading  Corporate houses and  high society  residences across the Globe. Santosh has worked for the Taj Group of Hotels , Times of India, Standard Chartered Bank , Citibank and Mastercard n a career spanning over 32 years.' 
            },
            // { src: require('../images/team/Rahu Budguzzar Best graffiti public street Artist.jpeg'), alt: 'Rahul', name: 'Rahul Budguzzar' },
        ]
    },
    sculptures: {
        label: 'Artist',
        content: [
            { src: require('../images/team/Rahu Budguzzar Best graffiti public street Artist.jpeg'), alt: 'Rahul', name: 'Rahul Budguzzar' },
            { src: require('../images/team/Aayush Sharma stone sculpture, silicone, bronze.jpeg'), alt: 'Image 2', name: 'Ayush Sharma' },
            { src: require('../images/team/Artist Naresh Kumar speslist portrait Filam banner Bollywood jalandar Punjab 1.jpeg'), alt: 'Naresh', name: 'Naresh Kumar' },
            { src: require('../images/team/Yilin Sculp artist china.jpeg'), alt: 'Image 2', name: 'Yilin Sculp' }
        ]
    },
};


const Team = () => {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);


    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const handleImageClick = (content) => {
        setSelectedImage(content);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);
    return (
        <div>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse justify-content-center" id="subNavbarNav">
                        <ul className="navbar-nav flex-wrap" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                            {Object.keys(allImages).map((category, index) => (
                                <li className="nav-item" style={{ margin: '5px', fontSize: '16px' }} key={index} >
                                    <button className="nav-link btn btn-link" onClick={() => handleCategoryChange(category)} style={{ marginRight: '20px', textDecoration: selectedCategory === category ? 'underline' : 'none' }}>
                                        {allImages[category].label}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav> */}
            <div className="container pt-4" style={{ backgroundColor: '#fafafa' }}>
                <div className="row">
                    {allImages[selectedCategory].content.map((content, index) => (
                        <div
                            className="mb-2 row flex-row align-items-center"
                            key={index}
                            // onClick={() => handleImageClick(content)}
                        >
                            <div className="image-container col-4">
                                <img
                                    src={content.src}
                                    alt={content.alt}
                                    className="img-fluid"
                                    style={{ height: '284px', width: '400px' }}
                                />
                                <div className="overlay">
                                    <i className="fas fa-plus"></i>
                                </div>
                                <h4 className="text-center mt-2">{content.name}</h4>
                            </div>
                            <div className='col-8'>
<span>
                            R B  Santosh Kumar,Chairman of RBSK Arts is a Global Artist and holds 15 Solo exhibitions and 15 International  group shows to his credit. He is also an Alumni of Harvard Business School, Boston. In 2023, Santosh won the award for the finest contemporary artist in India. He works with oil, Acrylic and water color mediums in his artistic creations.  He believes in the human form , its myriad postures and the variety of expressions and translates them in his art with the grace of an artist and the authenticity of a sensitive human being. His paintings cover various subjects ranging from still art to natural landscapes, abstracts as well as  human figures. Each of his paintings capture a moment  or feeling that are pristine and deliver a magical experience to the viewer. Every frame exhibits proficient strokes in a different medium  and various colors of the palette reflecting the artists love for a joyful and harmonious everyday life. Santosh has earned credibility as an artist and some of his works  are displayed at the Rashtrapati Bhavan in India (Official Residence and Office of the President of India ).His  paintings have been picked  up by Art lovers and are being  valued as the choice of the discerning collectors and Investors of Art . He is a recipient of a silver medal from the President of India. As of today some of his paintings adorn the walls of leading  Corporate houses and  high society  residences across the Globe. Santosh has worked for the Taj Group of Hotels , Times of India, Standard Chartered Bank , Citibank and Mastercard n a career spanning over 32 years.
                            </span>
                            </div>
                            
                        </div>
                    ))}
                </div>
            </div>


            {/* Modal Pop-up */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedImage?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          <img
            src={selectedImage?.src}
            alt={selectedImage?.alt}
            className="img-fluid"
            style={{ height: '350px', marginRight: '20px' , width: '400px'}}
          />
          <div>
            <h5>Details:</h5>
            <p>{selectedImage?.details}</p>
          </div>
        </Modal.Body>
      </Modal>
        </div>
    );
}

export default Team;
