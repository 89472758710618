import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Button, Collapse } from 'react-bootstrap';

const allImages = {
  sculptures: {
    label: 'Sculpture',
    images: [
      { src: require('../images/Sculpture/1.jpeg'), alt: 'Image 1' },
      { src: require('../images/Sculpture/2.jpeg'), alt: 'Image 2', position: 'center' },
      { src: require('../images/Sculpture/3.jpeg'), alt: 'Image 3', position: 'top' },
      { src: require('../images/Sculpture/4.jpeg'), alt: 'Image 4', position: 'bottom' },
      { src: require('../images/Sculpture/5.jpeg'), alt: 'Image 5', position: 'center'  },
      { src: require('../images/Sculpture/6.jpeg'), alt: 'Image 6', position: 'top' },
      { src: require('../images/Sculpture/7.jpeg'), alt: 'Image 7' },
      { src: require('../images/Sculpture/8.jpg'), alt: 'Image 8', position: 'top' },
      { src: require('../images/Sculpture/9.jpg'), alt: 'Image 9' },
      { src: require('../images/Sculpture/10.jpg'), alt: 'Image 10' },
      { src: require('../images/Sculpture/11.jpg'), alt: 'Image 11' },
      { src: require('../images/Sculpture/12.jpeg'), alt: 'Image 12', position: 'top' },
      { src: require('../images/Sculpture/13.jpeg'), alt: 'Image 13' },
      { src: require('../images/Sculpture/14.jpeg'), alt: 'Image 14' },
      { src: require('../images/Sculpture/15.jpeg'), alt: 'Image 15', position: 'top' },
      { src: require('../images/Sculpture/16.jpeg'), alt: 'Image 16', position: 'top' },
    ],
  },
  graffiti_paintings: {
    label: 'Graffiti Paintings',
    images: [
      { src: require('../images/Graffiti/1.png'), alt: 'Image 5' },
      { src: require('../images/Graffiti/2.jpg'), alt: 'Image 5' },
      { src: require('../images/Graffiti/3.jpg'), alt: 'Image 5' },
      { src: require('../images/Graffiti/4.jpg'), alt: 'Image 5' },
      { src: require('../images/Graffiti/5.jpg'), alt: 'Image 5' },
      { src: require('../images/Graffiti/6.jpg'), alt: 'Image 6' },
      // { src: require('../images/Graffiti/7.jpg'), alt: 'Image 7' },
      { src: require('../images/Graffiti/8.jpg'), alt: 'Image 8' },
      { src: require('../images/Graffiti/9.jpg'), alt: 'Image 9' },
      { src: require('../images/Graffiti/10.jpg'), alt: 'Image 10' },
      { src: require('../images/Graffiti/11.jpg'), alt: 'Image 11' },
      { src: require('../images/Graffiti/12.jpg'), alt: 'Image 12' },
      { src: require('../images/Graffiti/13.jpg'), alt: 'Image 13' },
      { src: require('../images/Graffiti/14.jpg'), alt: 'Image 14' },
      { src: require('../images/Graffiti/15.jpg'), alt: 'Image 15' },
      // { src: require('../images/Graffiti/16.jpg'), alt: 'Image 16' },
      // { src: require('../images/Graffiti/17.jpg'), alt: 'Image 17' },
      // { src: require('../images/Graffiti/18.jpg'), alt: 'Image 18' },
    ]
  },
  oil: {
    label: 'Oil Paintings',
    images: [
      // { src: require('../images/oil/1.jpg'), alt: 'Image 1' },
      { src: require('../images/oil/2.jpeg'), alt: 'Image 2' },
      { src: require('../images/oil/3.jpg'), alt: 'Image 3' },
      { src: require('../images/oil/4.jpg'), alt: 'Image 4' },
      { src: require('../images/oil/5.jpg'), alt: 'Image 5', position: 'center' },
      { src: require('../images/oil/6.jpg'), alt: 'Image 6' },
      { src: require('../images/oil/7.jpg'), alt: 'Image 7' },
      { src: require('../images/oil/8.jpg'), alt: 'Image 8' },
      // { src: require('../images/oil/9.png'), alt: 'Image 9' },
      { src: require('../images/oil/10.png'), alt: 'Image 10', position: '70% 30%' },
      { src: require('../images/oil/11.jpeg'), alt: 'Image 11' },
      { src: require('../images/oil/12.jpeg'), alt: 'Image 12' },
      { src: require('../images/oil/13.jpeg'), alt: 'Image 13' },
      { src: require('../images/oil/14.jpeg'), alt: 'Image 14' },
      { src: require('../images/oil/15.jpeg'), alt: 'Image 15' },
      { src: require('../images/oil/16.jpeg'), alt: 'Image 16' },
      { src: require('../images/oil/17.jpeg'), alt: 'Image 17' },
      { src: require('../images/oil/18.jpeg'), alt: 'Image 18' },
      // { src: require('../images/oil/19.jpeg'), alt: 'Image 19' },
      // { src: require('../images/oil/20.jpeg'), alt: 'Image 20' },
      // { src: require('../images/oil/21.jpeg'), alt: 'Image 21' },
      // { src: require('../images/oil/22.jpeg'), alt: 'Image 22' },
      // { src: require('../images/oil/23.jpeg'), alt: 'Image 23' },
      // { src: require('../images/oil/24.jpeg'), alt: 'Image 24' },
      // { src: require('../images/oil/25.jpeg'), alt: 'Image 25' },
      // { src: require('../images/oil/26.jpeg'), alt: 'Image 26' },
    ],
  },

  acrylic_paintings: {
    label: 'Acrylic Paintings',
    images: [
      { src: require('../images/acrylic/1.jpg'), alt: 'Image 1' },
      { src: require('../images/acrylic/2.jpg'), alt: 'Image 2' },
      { src: require('../images/acrylic/3.jpg'), alt: 'Image 3' },
      { src: require('../images/acrylic/4.jpg'), alt: 'Image 4' },
      { src: require('../images/acrylic/5.jpg'), alt: 'Image 5' },
      { src: require('../images/acrylic/6.jpg'), alt: 'Image 6' },
      { src: require('../images/acrylic/7.jpg'), alt: 'Image 7', position: 'top' },
      { src: require('../images/acrylic/8.jpg'), alt: 'Image 8' },
      { src: require('../images/acrylic/9.jpg'), alt: 'Image 9' },
      { src: require('../images/acrylic/10.jpeg'), alt: 'Image 10' },
      { src: require('../images/acrylic/11.jpeg'), alt: 'Image 11' },
      { src: require('../images/acrylic/12.jpeg'), alt: 'Image 12' },
      { src: require('../images/acrylic/13.jpeg'), alt: 'Image 13' },
      { src: require('../images/acrylic/14.jpeg'), alt: 'Image 14' },
      { src: require('../images/acrylic/15.jpeg'), alt: 'Image 15' },
      { src: require('../images/acrylic/16.jpeg'), alt: 'Image 16' },
    ]
  },
  water_color: {
    label: 'Water Color Paintings',
    images: [
      // { src: require('../images/water color/1.jpeg'), alt: 'Image 1', position: '50% 30%' },
      { src: require('../images/water color/2.jpeg'), alt: 'Image 2' },
      { src: require('../images/water color/3.jpeg'), alt: 'Image 3' },
      { src: require('../images/water color/4.jpeg'), alt: 'Image 4' },
      { src: require('../images/water color/5.jpeg'), alt: 'Image 5' },
      { src: require('../images/water color/6.jpeg'), alt: 'Image 6', position: 'bottom' },
      { src: require('../images/water color/7.jpeg'), alt: 'Image 7', position: '50% 50%' },
      { src: require('../images/water color/8.jpeg'), alt: 'Image 8' },
      { src: require('../images/water color/9.jpeg'), alt: 'Image 9' },
      { src: require('../images/water color/10.jpeg'), alt: 'Image 10' },
      { src: require('../images/water color/11.jpeg'), alt: 'Image 11' },
      { src: require('../images/water color/12.jpeg'), alt: 'Image 12', position: '60% 40%' },
      // { src: require('../images/water color/13.jpeg'), alt: 'Image 13' },
      { src: require('../images/water color/14.jpeg'), alt: 'Image 14' },
      { src: require('../images/water color/15.jpeg'), alt: 'Image 15' },
      { src: require('../images/water color/16.jpeg'), alt: 'Image 16' },
      // { src: require('../images/water color/17.jpeg'), alt: 'Image 17' },
      { src: require('../images/water color/18.jpeg'), alt: 'Image 18' },
      { src: require('../images/water color/19.jpeg'), alt: 'Image 19' },
    ]
  },
  mural_arts: {
    label: 'Mural Arts',
    images: [
      { src: require('../images/Mural Arts/1.jpeg'), alt: 'Image 1', position: '80% 20%' },
      { src: require('../images/Mural Arts/2.jpeg'), alt: 'Image 2', position: '85% 15%' },
      { src: require('../images/Mural Arts/3.jpeg'), alt: 'Image 3', position: '80% 20%' },
      { src: require('../images/Mural Arts/4.jpeg'), alt: 'Image 4', position: 'top' },
      { src: require('../images/Mural Arts/5.jpeg'), alt: 'Image 5',  },
      { src: require('../images/Mural Arts/7.jpeg'), alt: 'Image 7' },
      { src: require('../images/Mural Arts/8.jpeg'), alt: 'Image 8', position: '80% 20%' },
      // { src: require('../images/Mural Arts/9.jpeg'), alt: 'Image 9' },
      { src: require('../images/Mural Arts/10.jpeg'), alt: 'Image 10' },
      { src: require('../images/Mural Arts/11.jpeg'), alt: 'Image 11', position: '80% 20%' },
      { src: require('../images/Mural Arts/6.jpeg'), alt: 'Image 6' },
      { src: require('../images/Mural Arts/12.jpeg'), alt: 'Image 12', position: '80% 20%' },
      { src: require('../images/Mural Arts/13.jpeg'), alt: 'Image 13', position: '80% 20%' },
      { src: require('../images/Mural Arts/14.jpeg'), alt: 'Image 14' },
      { src: require('../images/Mural Arts/15.jpeg'), alt: 'Image 15' },
      { src: require('../images/Mural Arts/21.jpeg'), alt: 'Image 21' },
      { src: require('../images/Mural Arts/16.jpeg'), alt: 'Image 16', position: 'center' },
      { src: require('../images/Mural Arts/17.jpeg'), alt: 'Image 17', position: '70% 30%' },
      { src: require('../images/Mural Arts/18.jpeg'), alt: 'Image 18' },
      { src: require('../images/Mural Arts/19.jpeg'), alt: 'Image 19' },
      { src: require('../images/Mural Arts/20.jpeg'), alt: 'Image 20' },
      { src: require('../images/Mural Arts/22.jpeg'), alt: 'Image 22' },
      { src: require('../images/Mural Arts/23.jpeg'), alt: 'Image 23' },
      { src: require('../images/Mural Arts/24.jpeg'), alt: 'Image 24' },
      { src: require('../images/Mural Arts/25.jpeg'), alt: 'Image 25' },
      { src: require('../images/Mural Arts/26.jpeg'), alt: 'Image 26' },
      // { src: require('../images/Mural Arts/27.jpeg'), alt: 'Image 27', position: '50% 50%' },
      // { src: require('../images/Mural Arts/28.jpeg'), alt: 'Image 28', position: 'center' },
      { src: require('../images/Mural Arts/29.jpeg'), alt: 'Image 29' },
      { src: require('../images/Mural Arts/30.jpeg'), alt: 'Image 30' },
      { src: require('../images/Mural Arts/31.jpeg'), alt: 'Image 31' },
    ]
  },
  portrait_paintings: {
    label: 'Portrait Paintings',
    images: [
      { src: require('../images/portrait/1.jpeg'), alt: 'Image 1' , position: 'center' },
      // { src: require('../images/portrait/2.jpeg'), alt: 'Image 2', position: 'center' },
      { src: require('../images/portrait/3.jpeg'), alt: 'Image 3', position: 'center' },
      { src: require('../images/portrait/4.jpeg'), alt: 'Image 4', position: 'center' },
      { src: require('../images/portrait/5.jpg'), alt: 'Image 5', position: 'center' },
      { src: require('../images/portrait/6.jpg'), alt: 'Image 6', position: '70% 30%' },
      { src: require('../images/portrait/13.jpg'), alt: 'Image 13', position: '80% 20%' },
      { src: require('../images/portrait/8.jpg'), alt: 'Image 8', position: 'center', position: 'center' },
      { src: require('../images/portrait/9.jpg'), alt: 'Image 9', position: 'center' },
      { src: require('../images/portrait/10.jpg'), alt: 'Image 10', position: '80% 20%' },
      { src: require('../images/portrait/11.jpg'), alt: 'Image 11', position: 'top' },
      { src: require('../images/portrait/12.jpg'), alt: 'Image 12', position: 'top' },
      { src: require('../images/portrait/7.jpg'), alt: 'Image 7', position: 'center' },
      { src: require('../images/portrait/14.jpg'), alt: 'Image 14', position: '80% 20%' },
      // { src: require('../images/portrait/15.jpg'), alt: 'Image 15', position: 'center' },
      { src: require('../images/portrait/16.jpg'), alt: 'Image 16', position: 'center' },
      { src: require('../images/portrait/17.jpg'), alt: 'Image 17', position: 'center' },
      { src: require('../images/portrait/18.jpg'), alt: 'Image 18', position: '85% 15%' },
      { src: require('../images/portrait/19.jpg'), alt: 'Image 19', position: 'top' },
      // { src: require('../images/portrait/20.jpg'), alt: 'Image 20', position: 'center' },
      { src: require('../images/portrait/21.jpg'), alt: 'Image 21', position: '80% 20%', position: 'center' },
      { src: require('../images/portrait/22.jpg'), alt: 'Image 22', position: '80% 20%', },
      { src: require('../images/portrait/23.jpg'), alt: 'Image 23', position: '80% 20%' },
      
    ]
  },
  film_set_design: {
    label: 'Film Set Design',
    images: [
      // { src: require('../images/Film Set Design/1.png'), alt: 'Image 5' },
      { src: require('../images/Film Set Design/2.png'), alt: 'Image 5' },
      { src: require('../images/Film Set Design/3.jpg'), alt: 'Image 3' },
      { src: require('../images/Film Set Design/4.jpg'), alt: 'Image 4' },
      { src: require('../images/Film Set Design/5.jpg'), alt: 'Image 5' },
      { src: require('../images/Film Set Design/6.jpg'), alt: 'Image 6' },
      { src: require('../images/Film Set Design/7.jpg'), alt: 'Image 7' },
      { src: require('../images/Film Set Design/8.jpg'), alt: 'Image 8' },
      { src: require('../images/Film Set Design/9.jpg'), alt: 'Image 9' },
      { src: require('../images/Film Set Design/10.jpg'), alt: 'Image 10' },
      { src: require('../images/Film Set Design/11.jpg'), alt: 'Image 11' },
      // { src: require('../images/Film Set Design/12.jpg'), alt: 'Image 12' },
      { src: require('../images/Film Set Design/13.jpg'), alt: 'Image 13' },
      { src: require('../images/Film Set Design/14.jpg'), alt: 'Image 14' },
      { src: require('../images/Film Set Design/15.jpg'), alt: 'Image 15' },
      { src: require('../images/Film Set Design/16.jpg'), alt: 'Image 16' },
      { src: require('../images/Film Set Design/17.jpg'), alt: 'Image 17' },
      { src: require('../images/Film Set Design/18.jpg'), alt: 'Image 18' }
    ]
  },
  tourism_park_design: {
    label: 'Tourism Park Design',
    images: [
      { src: require('../images/tourism park design/1.jpg'), alt: 'Image 5', position: '80% 20%' },
      { src: require('../images/tourism park design/2.jpg'), alt: 'Image 5', position: '80% 20%' },
      { src: require('../images/tourism park design/3.jpg'), alt: 'Image 5', position: 'center' },
      { src: require('../images/tourism park design/4.jpg'), alt: 'Image 4', position: 'center' },
      { src: require('../images/tourism park design/5.jpg'), alt: 'Image 5', position: 'top' },
      { src: require('../images/tourism park design/6.jpg'), alt: 'Image 6', position: 'center' },
      // { src: require('../images/tourism park design/7.jpg'), alt: 'Image 7', position: 'center' },
      { src: require('../images/tourism park design/8.jpg'), alt: 'Image 8', position: 'center' },
      { src: require('../images/tourism park design/9.jpg'), alt: 'Image 9', position: 'center' },
      { src: require('../images/tourism park design/10.jpg'), alt: 'Image 10', position: 'center' },
      { src: require('../images/tourism park design/11.jpg'), alt: 'Image 11', position: '80% 20%' },
      { src: require('../images/tourism park design/12.jpg'), alt: 'Image 12', position: '80% 20%' },
      { src: require('../images/tourism park design/13.jpeg'), alt: 'Image 13', position: 'center' }
    ]
  }
};

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState('sculptures');
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setIsNavbarOpen(false);
  };

  const handleShow = (index) => {
    setCurrentIndex(index);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages[selectedCategory].images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + allImages[selectedCategory].images.length) % allImages[selectedCategory].images.length);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
        <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
            data-bs-toggle="collapse"
            data-bs-target="#subNavbarNav"
            aria-controls="subNavbarNav"
            aria-expanded={isNavbarOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse in={isNavbarOpen}>
          <div className="collapse navbar-collapse justify-content-center" id="subNavbarNav">
            <ul className="navbar-nav flex-wrap justify-content-center" style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {Object.keys(allImages).map((category, index) => (
                <li className="nav-item" style={{ margin: '5px', fontSize: '16px' }} key={index}>
                  <button className="nav-link btn btn-link" onClick={() => handleCategoryChange(category)} style={{ marginRight: '20px', textDecoration: selectedCategory === category ? 'underline' : 'none' }}>
                    {allImages[category].label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          </Collapse>
        </div>
      </nav>
      <div className="container mt-4">
        <div className="row">
          {allImages[selectedCategory].images.map((image, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <img
                src={image.src}
                alt={image.alt}
                className="img-fluid"
                style={{ height: '200px', width: '280px', cursor: 'pointer', objectFit: `${image.position ? 'cover' : ''}`, objectPosition: `${image.position}`}}
                onClick={() => handleShow(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Modal for full-screen image display */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body style={{ backgroundColor: 'black', position: 'relative', padding: 0 }}>
          <Button variant="secondary" onClick={prevImage} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}>
            &lt;
          </Button>
          <img
            src={allImages[selectedCategory].images[currentIndex].src}
            alt={allImages[selectedCategory].images[currentIndex].alt}
            className="img-fluid"
            style={{ maxHeight: '85vh', display: 'block', margin: '0 auto' }}
          />
          <Button variant="secondary" onClick={nextImage} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}>
            &gt;
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Portfolio;
