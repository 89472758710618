import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-light text-center text-lg-start mt-4" >
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase" style={{fontSize: '18px'}}>Contact Us</h5>
            <ul className="list-unstyled mb-0">
              <li style={{ margin: '0px', fontSize: '13px'}}>
              <p style={{ margin: '0px' }}>RBSK Arts Pvt. Ltd.</p>
                <p style={{ margin: '0px' }}>I-Thum C-605, Plot No. A-40</p>
                <p style={{ margin: '0px' }}>Sector-62, Noida 201301 India</p>
                <p style={{ margin: '0px' }}>Email: rbsantoshkumar@rbskarts.com</p>

              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase" style={{fontSize: '18px'}} >Follow Us</h5>
            <ul className="list-unstyled mb-0" style={{ margin: '0px', fontSize: '13px'}}>
              <li>
                <a href="#!" className="text-dark">Facebook</a>
              </li>
              <li>
                <a href="#!" className="text-dark">Twitter</a>
              </li>
              <li>
                <a href="#!" className="text-dark">Instagram</a>
              </li>
              <li>
                <a href="#!" className="text-dark">LinkedIn</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase" style={{fontSize: '18px'}}>Quick Links</h5>
            <ul className="list-unstyled mb-0" style={{ margin: '0px', fontSize: '13px'}}>
              <li>
                <a href="#!" className="text-dark">Privacy Policy</a>
              </li>
              <li>
                <a href="#!" className="text-dark">Terms of Service</a>
              </li>
              <li>
                <a href="#!" className="text-dark">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; RBSK Arts Private Limited
      </div>
    </footer>
  );
};

export default Footer;
